<template>
    <div class="rule_page">
        <div class="top_part">
            <div class="title_part">
                <div class="arrow_left" @click="goHome"><img src="../assets/imgs/arrow_left.png" alt=""></div>
                <div class="rules_text">{{ $t('m.giftrechargerecords') }}</div>
            </div>
        </div>
        <van-loading v-if="!isFlag"></van-loading>
        <div :class="['content_part', lang === 2 ? 'rtl' : '']" v-else-if="tList.length !== 0">
            <van-loading v-if="!isFlag"></van-loading>
            <div v-else>
                <van-pull-refresh v-model="refreshing" 
                    :loading-text="$t('m.loading')"
                    :pulling-text="$t('m.loading')"
                    @refresh="onRefresh" >
                    <!-- 释放提示 -->
                    <template #loosing>
                        <van-loading></van-loading>
                    </template>
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="$t('m.nomore')"
                        :loading-text="$t('m.loading')"
                        @load="onLoad"
                    >
                         <div :class="['records_item', lang === 2 ? 'rtl' : '']" v-for="(item, index) in tList" :key="index">
                            <div class="left_part">
                                <div class="gift_name">{{ item.coins }} {{ $t('m.coins') }}</div>
                                <div class="buy_date">{{  item.time }}</div>
                            </div>
                            <div class="right_part">
                                <div :class="['number', lang === 2 ? 'ltr':'']">+{{ item.amount }} {{ item.currency }}</div>
                            </div>
                         </div>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>        
        <nodata class="nodata_box" v-else></nodata>
    </div>
</template>

<script>
import { offerTaskhistory } from '../assets/js/request'
import jumpFn from '../mixins/jumpFn'
import utilsFn from '@/assets/js/commonData';
import {timeConvert} from "@/assets/js/utils";
export default ({
    name: 'giftpackRules',
    mixins: [jumpFn],
    data() {
        return {
            page: 1,
            isFlag: false,
            loading: false,
            finished: false,
            refreshing: false,
            offer_order_id: this.$route.query.offer_order_id,
            tList: [],
            //0-正在进行 1-已完成 2-已过期
            statusTypes: {
                0: this.$t('m.going'),
                1: this.$t('m.compltedandsent'),
                2: this.$t('m.expired')
            },
            statusStyles: {
                0:'no_complted',
                1:'complted',
                2:'no_complted'
            },
            ifRtl: '',
            lang: this.globalData.lang !== "" ? Number(this.globalData.lang) : Number(sessionStorage.getItem('lang'))
        }
    },
    created(){
        //console.log('-------------------------- 礼包历史购买记录 ------------------------')
        //console.log(this.$route, '...........当前路由参数')
        //console.log(this.globalData, '............全局...........', this.lang, '...........lang')
        this.ifRtl = utilsFn.getRtl(this, this.lang, 2)
        this.getofferTaskrecords()
    },
    methods: {    
        onLoad() {
            setTimeout(() => {
                if (this.refreshing) {
                    this.tList = [];
                    this.refreshing = false;
                }
                this.getofferTaskrecords()
            }, 1000);
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.isFlag = false;

            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.page = 1
            this.onLoad();
        },
        //checkout礼包历史购买情况    
        async getofferTaskrecords(){
            let params = {
                uid: this.globalData.uid === "" ? sessionStorage.getItem('uid') : this.globalData.uid,
                token: this.globalData.userINFO.token === "" || this.globalData.userINFO.token === undefined  ? sessionStorage.getItem('token') : this.globalData.userINFO.token,
                offer_order_id: this.offer_order_id, //成就礼包标识码
                page: this.page,
                lang: this.lang,
            }

            sessionStorage.setItem('uid', params.uid)
            sessionStorage.setItem('token', params.token)
            console.log(JSON.parse(JSON.stringify(params)), '..........参数')

            await offerTaskhistory(params).then(res => {
                if(res.code !== 2501) {
                    let {list, page} = res.data
                    this.loading = false
                    list.forEach(item => {
                        item.time = timeConvert(item.c_time)
                    })
                    //console.log(res, '.............checkout礼包历史购买情况')
                    this.tList.push(...list)    
                    this.finished = page === "" ? true : false
                    if( page !== "" ) {
                        this.page++
                    }
                } 
                this.isFlag = true                
            })
        }
    }
})
</script>

<style lang="scss" scoped>
@import '../assets/css/common.scss';
.top_part {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #fff;
    .title_part {
        @include flex-cen;
        box-sizing: border-box;
        height: 44px;
        padding: 10px 0;
        position: relative;

        .arrow_left {
            position: absolute;
            left: 16px;

            img {
                width: 24px;
                height: 24px;
            }
        }

        .rules_text {
            flex: 4;
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 19px;
        }
    }
}

.content_part {
    position: relative;
    z-index: 0;
    margin-top: 44px;
    box-sizing: border-box;
    text-align: left;
    padding: 12px 16px 8px 16px; 
    height: 690px;
    overflow-y: hidden;

    .van-loading {
        text-align: center;
    }

    .complted {
        color: #73D13D;
    }
    .no_complted {
        color: #B3B3B3;
    }

    .van-pull-refresh {
        .van-pull-refresh__track {
            .van-list {                 
                height: 670px;
                overflow-y: auto;
                .records_item {
                    width: 100%;
                    height: 74px;
                    border-radius: 0px 0px 0px 0px;
                    opacity: 1;
                    border-bottom: 0.02rem solid #E6E6E6;
                    @include flex-between;
                    .left_part {
                        @include flex-column-top;
                        flex: 4;
                        .gift_name {
                            font-size: 15px;
                            font-weight: 600;
                            color: #333333;
                            line-height: 18px;
                        }
                        .buy_date {
                            font-size: 13px;
                            font-weight: 400;
                            color: #B3B3B3;
                            line-height: 15px;
                            margin-top: 8px;
                        }
                    }
                    .right_part {                        
                        flex: 2;
                        .number {
                            font-size: 21px;
                            font-weight: bold;
                            color: #FFC53D;
                            line-height: 25px;
                        }
                    }
                }
            }
        }
    }
}
.nodata_box {
    margin-top: 40vh;
}
</style>